import { ArrowBack, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { Chip } from 'components/chips'
import { CampaignDto } from 'dtos'
import { formatDate } from 'utils'
import CampaignAddEditDialog from './CampaignAddEditDialog'
import { useState } from 'react'
import * as controllers from 'controllers'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

interface CampaignDetailsProps {
  campaign: CampaignDto
  setCampaign: React.Dispatch<React.SetStateAction<CampaignDto>>
}

export default function CampaignDetailsCard({ campaign, setCampaign }: CampaignDetailsProps) {
  const [campaignAddEditDialogOpen, setCampaignAddEditDialogOpen] = useState<boolean>(false)
  const [isSavingCampaign, setIsSavingCampaign] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <>
      <CampaignAddEditDialog
        initialValues={campaign}
        isLoading={isSavingCampaign}
        onClose={() => {
          setCampaignAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingCampaign(true)
          controllers
            .updateCampaign(values)
            .then(response => {
              setCampaign(response.value)
              setCampaignAddEditDialogOpen(false)
              enqueueSnackbar('Campaign Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingCampaign(false)
            })
        }}
        open={campaignAddEditDialogOpen}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
                <Grid item>
                  <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                    {campaign.name}
                  </Typography>
                </Grid>

                {campaign.isActive && (
                  <Grid item>
                    <Chip variant='green'>Active</Chip>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm='auto' container spacing={1} alignItems='center'>
                <Grid item xs={12} sm='auto'>
                  <Button
                    color='primary'
                    onClick={() => {
                      navigate('/campaigns')
                    }}
                    startIcon={<ArrowBack />}
                    variant='text'
                  >
                    BACK
                  </Button>
                </Grid>

                <Grid item sx={{ alignSelf: 'stretch' }}>
                  <Divider orientation='vertical' />
                </Grid>

                <Grid item xs={12} sm='auto' alignItems='flex-end'>
                  <Button
                    onClick={() => {
                      setCampaignAddEditDialogOpen(true)
                    }}
                    variant='text'
                  >
                    EDIT&nbsp;
                    <Edit fontSize='small' />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2'>Description</Typography>
              <Typography color='primary' variant='body1'>
                {campaign.description}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Start Date</Typography>
              <Typography color='primary' variant='body1'>
                {campaign.startDate ? formatDate(campaign.startDate) : ''}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>End Date</Typography>
              <Typography color='primary' variant='body1'>
                {campaign.endDate ? formatDate(campaign.endDate) : ''}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
