import { apiClient } from '_app'
import {
  ApiResponse,
  InvoiceToSendDto,
  PrepaidHoursDto,
  ProjectWithNoActivityFor7PlusDaysDto,
  TeamMemberScheduleDto,
  TeamMemberStatusDto,
  TeamMemberContactDto,
  TimePunchDto,
  WorkOrderReleaseTaskTimeEntryDto
} from 'dtos'

export const getTeamMemberSchedule = () =>
  apiClient
    .get<ApiResponse<TeamMemberScheduleDto[]>>('/api/dashboard/team-member-schedule')
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const getTeamMemberContact = () =>
  apiClient
    .get<ApiResponse<TeamMemberContactDto[]>>('/api/dashboard/team-member-contact')
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const getProjectsWithNoActivityFor7PlusDays = () =>
  apiClient
    .get<ApiResponse<ProjectWithNoActivityFor7PlusDaysDto[]>>(
      '/api/dashboard/projects-with-no-activity-for-7-plus-days'
    )
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const getPrepaidHoursRemaining = () =>
  apiClient
    .get<ApiResponse<PrepaidHoursDto[]>>('/api/dashboard/pre-paid-hours-remaining')
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const getInvoicesToSend = () =>
  apiClient
    .get<ApiResponse<InvoiceToSendDto[]>>('/api/dashboard/invoices-to-send')
    .then(res => res.data)
    .catch(reason => {
      throw new Error(reason)
    })

export const getTeamMemberStatuses = () =>
  apiClient
    .get<ApiResponse<TeamMemberStatusDto[]>>('/api/dashboard/team-member-statuses')
    .then(res => res.data)
    .catch(reason => {
      throw new Error(reason)
    })
