import {
  Card,
  CardContent,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import { OpportunityActivityDto, OpportunityDto } from 'dtos'
import React, { useEffect, useState } from 'react'
import { useDebounce, formatDate } from 'utils'
import * as controllers from 'controllers'
import { OpportunitiesParameters } from 'parameters'
import { useLoadingState } from 'hooks'

export default function OpportunityActivityHistoryCard({ opportunity }: { opportunity: OpportunityDto }) {
  const [opportunityActivity, setOpportunityActivity] = useState<OpportunityActivityDto[]>([])
  const [loadingState, setLoadingState] = useLoadingState({
    isGettingOpportunityActivity: false
  })

  const [parameters, setParameters] = useState<OpportunitiesParameters>({
    page: 0,
    pageSize: 100,
    order: 'desc',
    orderBy: 'activityDate'
  })

  const [totalCount, setTotalCount] = useState<number>(0)

  const GetByOpportunityParametersAndGuid = useDebounce((opportunityGuid: string, parameters: OpportunitiesParameters) => {
    setLoadingState('isGettingOpportunityActivity', true)
    controllers
      .getByOpportunityParametersAndGuid(opportunityGuid, parameters)
      .then(response => {
        setOpportunityActivity(response.value ?? [])
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setLoadingState('isGettingOpportunityActivity', false)
      })
  }, 300)

  useEffect(() => {
    if (opportunity.id) {
      GetByOpportunityParametersAndGuid(opportunity.id, parameters)
    }
  }, [opportunity.id, parameters])

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item>
                <Typography fontWeight='bold' variant='h6'>
                  Opportunity Activity History
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Activity Date</TableCell>
                      <TableCell>Activity Type</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>Activity Note</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>Probability</TableCell>
                      <TableCell>Next Activity Date</TableCell>
                      <TableCell padding='checkbox' align='right'></TableCell>
                      <TableCell padding='checkbox' align='right'></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loadingState.isGettingOpportunityActivity ? (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ) : (
                      opportunityActivity.map(opportunityActivity => (
                        <TableRow key={opportunityActivity.id}>
                          <TableCell>
                            {opportunityActivity.activityDate ? formatDate(opportunityActivity.activityDate) : 'N/A'}
                          </TableCell>

                          <TableCell>{opportunityActivity.opportunityActivityType?.name}</TableCell>

                          <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                            {opportunityActivity.activityNote}
                          </TableCell>

                          <TableCell>{opportunityActivity.opportunityStatus?.name}</TableCell>

                          <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                            {opportunityActivity.opportunityProbability?.name || '-'}
                          </TableCell>

                          <TableCell>
                            {opportunityActivity.nextActivityDate ? formatDate(opportunityActivity.nextActivityDate) : 'N/A'}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>

                <TablePagination
                  component='div'
                  count={totalCount}
                  onPageChange={(_, page: number) => {
                    setParameters({
                      ...parameters,
                      page
                    })
                  }}
                  onRowsPerPageChange={e => {
                    setParameters({
                      ...parameters,
                      pageSize: e.target.value as unknown as number
                    })
                  }}
                  page={parameters.page}
                  rowsPerPage={parameters.pageSize}
                  labelRowsPerPage='Rows per page:'
                  rowsPerPageOptions={[
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                  ]}
                  SelectProps={{ fullWidth: false }}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
