import { ArrowBack, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material'
import { Chip } from 'components/chips'
import * as controllers from 'controllers'
import { CompanyDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import CompanyAddEditDialog from './CompanyAddEditDialog'
import { useNavigate } from 'react-router-dom'

interface CompanyDetailsProps {
  company: CompanyDto
  setCompany: React.Dispatch<React.SetStateAction<CompanyDto>>
}

export default function CompanyDetailsCard({ company, setCompany }: CompanyDetailsProps) {
  const [companyAddEditDialogOpen, setCompanyAddEditDialogOpen] = useState<boolean>(false)
  const [isSavingCompany, setIsSavingCompany] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <>
      <CompanyAddEditDialog
        initialValues={company}
        loading={isSavingCompany}
        onClose={() => {
          setCompanyAddEditDialogOpen(false)
        }}
        onSave={values => {
          const requestMethod = values.id ? controllers.updateCompany : controllers.createCompany

          setIsSavingCompany(true)
          requestMethod(values)
            .then(response => {
              setCompany(response.value)
              setCompanyAddEditDialogOpen(false)
              enqueueSnackbar('Company Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingCompany(false)
            })
        }}
        open={companyAddEditDialogOpen}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
                <Grid item>
                  <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                    {company.name}
                  </Typography>
                </Grid>

                {company.isActive && (
                  <Grid item>
                    <Chip variant='green'>Active</Chip>
                  </Grid>
                )}

                {!company.isActive && (
                  <Grid item>
                    <Chip variant='gray'>Inactive</Chip>
                  </Grid>
                )}

                {company.isVendor && (
                  <Grid item>
                    <Chip variant='purple'>Vendor</Chip>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm='auto' container spacing={1} alignItems='center'>
                <Grid item xs={12} sm='auto'>
                  <Button
                    color='primary'
                    onClick={() => {
                      navigate('/companies')
                    }}
                    startIcon={<ArrowBack />}
                    variant='text'
                  >
                    BACK
                  </Button>
                </Grid>

                <Grid item sx={{ alignSelf: 'stretch' }}>
                  <Divider orientation='vertical' />
                </Grid>

                <Grid item xs={12} sm='auto'>
                  <Button
                    endIcon={<Edit />}
                    onClick={() => {
                      setCompanyAddEditDialogOpen(true)
                    }}
                    variant='text'
                  >
                    EDIT
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Company Code</Typography>
              <Typography color='primary' variant='body1'>
                {company.companyCode}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Website</Typography>
              <Typography color='primary' variant='body1'>
                <Link href={company.website} target='_blank'>
                  {company.website}
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Campaigns</Typography>
              <Typography color='primary' variant='body1'>
                {company.campaigns?.map(campaign => campaign.name).join(', ')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Notes</Typography>
              <Typography color='primary' variant='body1'>
                {company.notes}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
