import * as yup from 'yup'
import { PersonDto } from './person'
import { WorkOrderDto } from './workOrder'
import { WorkOrderReleaseTaskDto } from './workOrderReleaseTask'
import { WorkOrderReleaseTaskTimeEntryDto } from './workOrderReleaseTaskTimeEntry'
import { WorkOrderReleaseTypeDto } from './workOrderReleaseType'
import { values } from 'lodash'
import { Schema } from '@mui/icons-material'

export class WorkOrderReleaseDto {
  id?: string = undefined
  releaseTitle?: string = undefined
  releaseNumber?: string = undefined
  isReleaseNumberNumeric?: boolean = undefined

  signOffDueDate?: string = undefined
  notes?: string = undefined
  isReleaseComplete?: boolean = false

  // This seems redundant now.
  isOpen: boolean = true
  isClosed: boolean = false

  definePhaseHoursAllowed?: number = undefined
  definePhaseHoursUsed?: number = undefined
  definePhaseHoursRemaining?: number = undefined
  definePhaseNextAction?: string = undefined
  definePhasePercentComplete?: string = undefined
  definePhaseDefinedBy?: string = undefined
  definePhaseStartDate?: string = undefined
  definePhaseDueDate?: string = undefined

  designPhaseHoursAllowed?: number = undefined
  designPhaseHoursUsed?: number = undefined
  designPhaseHoursRemaining?: number = undefined
  designPhaseNextAction?: string = undefined
  designPhasePercentComplete?: string = undefined
  designPhaseDefinedBy?: string = undefined
  designPhaseStartDate?: string = undefined
  designPhaseDueDate?: string = undefined

  developmentPhaseHoursAllowed?: number = undefined
  developmentPhaseHoursUsed?: number = undefined
  developmentPhaseHoursRemaining?: number = undefined
  developmentPhaseNextAction?: string = undefined
  developmentPhasePercentComplete?: string = undefined
  developmentPhaseDefinedBy?: string = undefined
  developmentPhaseStartDate?: string = undefined
  developmentPhaseDueDate?: string = undefined

  deliverPhaseHoursAllowed?: number = undefined
  deliverPhaseHoursUsed?: number = undefined
  deliverPhaseHoursRemaining?: number = undefined
  deliverPhaseNextAction?: string = undefined
  deliverPhasePercentComplete?: string = undefined
  deliverPhaseDefinedBy?: string = undefined
  deliverPhaseStartDate?: string = undefined
  deliverPhaseDueDate?: string = undefined

  productionDeploymentRequired?: boolean = false
  summaryReleaseHoursAllowed?: number = undefined
  summaryReleaseStartDate?: string = undefined
  summaryReleaseDueDate?: string = undefined

  workOrder?: WorkOrderDto = undefined
  workOrderReleaseType?: WorkOrderReleaseTypeDto = undefined
  workOrderReleaseTasks?: WorkOrderReleaseTaskDto[] = []
  workOrderReleaseTaskTimeEntry?: WorkOrderReleaseTaskTimeEntryDto = undefined
}

export const workOrderReleaseValidationSchema = yup.object({
  id: yup.string().nullable(),
  releaseTitle: yup
    .string()
    .max(200, 'Release Title cannot be more than 200 characters.')
    .required('Release Title is required.'),
  signOffDueDate: yup.date().required('Release Target Completion is required'),
  notes: yup.string().max(200, 'Release Notes cannot be more than 200 characters.').nullable(),
  isReleaseComplete: yup.boolean(),
  definePhaseHoursAllowed: yup.number().required('Hours Allowed is required'),
  definePhaseDefinedBy: yup.string().when('definePhaseHoursAllowed', (values, schema) => {
    const definePhaseHoursAllowed = values[0]
    if (definePhaseHoursAllowed !== 0) {
      return schema.required('Defined By is required')
    }
    return schema.nullable()
  }),
  definePhaseStartDate: yup.date().when('definePhaseHoursAllowed', (values, schema) => {
    const definePhaseHoursAllowed = values[0]
    if (definePhaseHoursAllowed !== 0) {
      return schema.required('Start date is required')
    }
    return schema.nullable()
  }),
  definePhaseDueDate: yup.date().when(['definePhaseHoursAllowed', 'definePhaseStartDate'], (values, schema) => {
    const definePhaseHoursAllowed = values[0]
    const definePhaseStartDate = values[1]
    if (definePhaseHoursAllowed !== 0) {
      if (definePhaseStartDate) {
        return schema.min(yup.ref('definePhaseStartDate'), 'Due date must be after start date').required('Due date is required')
      } else {
        return schema.required('Due date is required')
      }
    }
    return schema.nullable()
  }),
  designPhaseHoursAllowed: yup.number().required('Hours Allowed is required'),
  designPhaseDefinedBy: yup.string().when('designPhaseHoursAllowed', (values, schema) => {
    const designPhaseHoursAllowed = values[0]
    if (designPhaseHoursAllowed !== 0) {
      return schema.required('Defined By is required')
    }
    return schema.nullable()
  }),
  designPhaseStartDate: yup.date().when('designPhaseHoursAllowed', (values, schema) => {
    const designPhaseHoursAllowed = values[0]
    if (designPhaseHoursAllowed !== 0) {
      return schema.required('Start date is required')
    }
    return schema.nullable()
  }),
  designPhaseDueDate: yup.date().when(['designPhaseHoursAllowed', 'designPhaseStartDate'], (values, schema) => {
    const designPhaseHoursAllowed = values[0]
    const designPhaseStartDate = values[1]
    if (designPhaseHoursAllowed !== 0) {
      if (designPhaseStartDate) {
        return schema.min(yup.ref('designPhaseStartDate'), 'Due date must be after start date').required('Due date is required')
      } else {
        return schema.required('Due date is required')
      }
    }
    return schema.nullable()
  }),
  developmentPhaseHoursAllowed: yup.number().required('Hours Allowed is required'),
  developmentPhaseDefinedBy: yup.string().when('developmentPhaseHoursAllowed', (values, schema) => {
    const developmentPhaseHoursAllowed = values[0]
    if (developmentPhaseHoursAllowed !== 0) {
      return schema.required('Defined By is required')
    }
    return schema.nullable()
  }),
  developmentPhaseStartDate: yup.date().when('developmentPhaseHoursAllowed', (values, schema) => {
    const developmentPhaseHoursAllowed = values[0]
    if (developmentPhaseHoursAllowed !== 0) {
      return schema.required('Start date is required')
    }
    return schema.nullable()
  }),
  developmentPhaseDueDate: yup.date().when(['developmentPhaseHoursAllowed', 'developmentPhaseStartDate'], (values, schema) => {
    const developmentPhaseHoursAllowed = values[0]
    const developmentPhaseStartDate = values[1]
    if (developmentPhaseHoursAllowed !== 0) {
      if (developmentPhaseStartDate) {
        return schema
          .min(yup.ref('developmentPhaseStartDate'), 'Due date must be after start date')
          .required('Due date is required')
      } else {
        return schema.required('Due date is required')
      }
    }
    return schema.nullable()
  }),
  deliverPhaseHoursAllowed: yup.number().required('Hours Allowed is required'),
  deliverPhaseDefinedBy: yup.string().when('deliverPhaseHoursAllowed', (values, schema) => {
    const deliverPhaseHoursAllowed = values[0]
    if (deliverPhaseHoursAllowed !== 0) {
      return schema.required('Defined By is required')
    }
    return schema.nullable()
  }),
  deliverPhaseStartDate: yup.date().when('deliverPhaseHoursAllowed', (values, schema) => {
    const deliverPhaseHoursAllowed = values[0]
    if (deliverPhaseHoursAllowed !== 0) {
      return schema.required('Start date is required')
    }
    return schema.nullable()
  }),
  deliverPhaseDueDate: yup.date().when(['deliverPhaseHoursAllowed', 'deliverPhaseStartDate'], (values, schema) => {
    const deliverPhaseHoursAllowed = values[0]
    const deliverPhaseStartDate = values[1]
    if (deliverPhaseHoursAllowed !== 0) {
      if (deliverPhaseStartDate !== undefined) {
        return schema
          .min(yup.ref('deliverPhaseStartDate'), 'Due date must be after start date')
          .required('Due date is required')
      } else {
        return schema.required('Due date is required')
      }
    }
    return schema.nullable()
  })
})
