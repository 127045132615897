import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import BoringAvatar from 'boring-avatars'
import { List } from 'components/list'
import { useAuth, useLayout } from 'context'
import * as api from 'controllers'
import * as controllers from 'controllers'
import {
  CompanyDto,
  PersonDto,
  PersonPunchStatusDto,
  WorkOrderReleaseTaskDto,
  WorkOrderReleaseTaskTimeEntryDto,
  ToDoDto
} from 'dtos'
import { useLoadingState, useQuery } from 'hooks'
import { ToDosParameters, WorkOrderReleaseTasksParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { decimalToHhMm, formatDate, getTimePunched, useDebounce } from 'utils'
import TaskListCard from './TaskListCard'
import { WorkOrderReleaseTaskTimeEntryAddEditDialog, TodoAddEditDialog } from 'components'
import { enqueueSnackbar } from 'notistack'
import { Add, Email, Phone } from '@mui/icons-material'
import { ExplanationAccordion } from 'components/_template/accordion'
import Chip from 'components/chips/Chip'
import { checkBday, checkSpudversary } from 'utils'

export default function Tasks() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const query = useQuery()
  const { user } = useAuth()

  const [isGettingTeamMembers, setIsGettingTeamMembers] = useState<boolean>(false)
  const [isGettingProjectMangers, setIsGettingProjectManagers] = useState<boolean>(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)

  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [projectManagers, setProjectManagers] = useState<PersonDto[]>([])
  const [tasks, setTasks] = useState<WorkOrderReleaseTaskDto[]>([])
  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  const [teamMembersForStatusCard, setTeamMembersForStatusCard] = useState<PersonPunchStatusDto[]>([])
  const [toDos, setToDos] = useState<ToDoDto[]>([])

  const [timeEntry, setTimeEntry] = useState<WorkOrderReleaseTaskTimeEntryDto>(new WorkOrderReleaseTaskTimeEntryDto())
  const [toDo, setToDo] = useState<ToDoDto>(new ToDoDto())
  const [logTimeDialogOpen, setLogTimeDialogOpen] = useState<boolean>(false)
  const [toDoAddDialogOpen, setToDoAddDialogOpen] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)

  const {
    permissions: { CAN_ADD_TO_DOS, CAN_MARK_TO_DOS_AS_HIDDEN }
  } = useAuth()

  const [parameters, setParameters] = useState<WorkOrderReleaseTasksParameters>({
    page: 0,
    pageSize: 10,
    teamMemberId: '',
    projectManagerId: '',
    search: query.get('search') ?? '',
    statuses: ['isOpen', 'isComplete', 'needsEstimation', 'needsDueDate'],
    activeOnly: true,
    companyId: '',
    workOrderReleaseId: '',
    order: 'asc',
    orderBy: 'priority'
  })

  const [ToDosParameters, setToDosParameters] = useState<ToDosParameters>({
    page: 0,
    pageSize: 999,
    teamMemberId: user?.id,
    includeDone: CAN_MARK_TO_DOS_AS_HIDDEN ? true : false,
    includeHidden: false
  })

  const [loadingState, setLoadingState] = useLoadingState({
    isGettingTasks: false,
    isCreatingTask: false,
    isCreatingTimeEntry: false
  })

  const getTasks = useDebounce((parameters: WorkOrderReleaseTasksParameters) => {
    setLoadingState('isGettingTasks', true)
    setIsLayoutLoading(true)
    controllers
      .getWorkOrderReleaseTasks(parameters)
      .then(({ totalCount, value }) => {
        setCount(totalCount ?? 0)
        setTasks(value)
      })
      .finally(() => {
        setLoadingState('isGettingTasks', false)
        setIsLayoutLoading(false)
      })
  }, 300)

  const getToDos = useDebounce((parameters: ToDosParameters) => {
    setIsLayoutLoading(true)
    controllers
      .getToDos(parameters)
      .then(reponse => {
        setToDos(reponse.value)
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  useEffect(() => {
    setTitle('Tasks')
    return () => {
      setTitle('')
    }
  }, [])

  useEffect(() => {
    getTasks(parameters)
  }, [parameters])

  useEffect(() => {
    setIsGettingTeamMembers(true)
    api
      .getPeople({ page: 0, pageSize: 999, permission: 'TASKS', statuses: ['isUser'] })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingTeamMembers(false)
      })

    setIsGettingTeamMembers(true)
    api
      .getPeoplePunchStatuses()
      .then(({ value }) => {
        setTeamMembersForStatusCard(value)
        console.log(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingTeamMembers(false)
      })

    setIsGettingProjectManagers(true)
    api
      .getPeople({
        page: 0,
        pageSize: 999,
        permission: 'PROJECT_MANAGER',
        statuses: ['isUser']
      })
      .then(({ value }) => {
        setProjectManagers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingProjectManagers(false)
      })
  }, [])

  useEffect(() => {
    setParameters({
      ...parameters,
      teamMemberId: user!.id
    })
  }, [user])

  useEffect(() => {
    if (parameters.teamMemberId) {
      setIsGettingCompanies(true)
      api
        .getCompanies({ page: 0, pageSize: 999, teamMemberId: parameters.teamMemberId })
        .then(({ value }) => {
          setCompanies(value)
        })
        .catch(() => {})
        .finally(() => {
          setIsGettingCompanies(false)
        })
    }
    if (parameters.teamMemberId) {
      getToDos(ToDosParameters)
    }
  }, [parameters.teamMemberId])

  return (
    <>
      <WorkOrderReleaseTaskTimeEntryAddEditDialog
        open={logTimeDialogOpen}
        initialValues={timeEntry}
        isLoading={loadingState.isCreatingTimeEntry}
        onClose={() => {
          setLogTimeDialogOpen(false)
          setTimeEntry(new WorkOrderReleaseTaskTimeEntryDto())
        }}
        onSave={values => {
          console.log(values)
          setLoadingState('isCreatingTimeEntry', true)
          api
            .createWorkOrderReleaseTaskTimeEntry(values)
            .then(() => {
              enqueueSnackbar('Time Entry Saved Successfully!', { variant: 'success' })
              setLogTimeDialogOpen(false)
              getTasks(parameters)
            })
            .finally(() => {
              setLoadingState('isCreatingTimeEntry', false)
            })
        }}
      />
      <TodoAddEditDialog
        open={toDoAddDialogOpen}
        initialValues={{ ...toDo, teamMember: teamMembers.filter(t => t.id === parameters.teamMemberId)[0] }}
        onClose={() => {
          setToDoAddDialogOpen(false)
        }}
        onSave={values => {
          api.createToDos(values).then(() => {
            setToDoAddDialogOpen(false)
            enqueueSnackbar('To Do Saved Successfully!', { variant: 'success' })
            getToDos(ToDosParameters)
          })
        }}
      />
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography fontWeight='bold' variant='h5'>
                Tasks
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                <Grid item xs={12} sm='auto'>
                  <TextField
                    label='Team Member'
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        teamMemberId: e.target.value,
                        projectManagerId: '',
                        companyId: ''
                      })
                      setToDosParameters({
                        ...ToDosParameters,
                        teamMemberId: e.target.value
                      })
                    }}
                    select
                    sx={{ minWidth: 240 }}
                    value={parameters.teamMemberId || ''}
                  >
                    {teamMembers.map(teamMember => (
                      <MenuItem key={teamMember.id} value={teamMember.id}>
                        {teamMember.firstName} {teamMember.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm='auto'>
                  <TextField
                    label='Project Manager'
                    onChange={e => {
                      setParameters({ ...parameters, projectManagerId: e.target.value })
                    }}
                    select
                    sx={{ minWidth: 240 }}
                    value={parameters.projectManagerId || ''}
                  >
                    <MenuItem value=''>All Project Managers</MenuItem>
                    {projectManagers.map(projectManager => (
                      <MenuItem key={projectManager.id} value={projectManager.id}>
                        {projectManager.firstName} {projectManager.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm='auto'>
                  <TextField
                    label='Company'
                    onChange={e => {
                      setParameters({ ...parameters, companyId: e.target.value })
                    }}
                    select
                    sx={{ minWidth: 240 }}
                    value={parameters.companyId || ''}
                  >
                    <MenuItem value=''>All Companies</MenuItem>
                    {companies.map(company => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Grid item container alignItems={'flex-start'}>
            <Grid item xs={12} lg={8}>
              {tasks.map(task => (
                <Grid item xs={12} sx={{ mt: 1 }} key={task.id}>
                  <TaskListCard
                    refreshTasks={() => {
                      getTasks(parameters)
                    }}
                    onLogTime={() => {
                      setTimeEntry({
                        ...new WorkOrderReleaseTaskTimeEntryDto(),
                        workOrderReleaseTask: task
                      })
                      setLogTimeDialogOpen(true)
                    }}
                    task={task}
                  />
                </Grid>
              ))}
              <Grid mt={1}>
                <ExplanationAccordion>
                  Sorted by Due Date, then company, project, WO#.|| For the Due Date shows Past Due if older than today (in
                  Red), Today if Due Today (in Blue), Tomorrow if Due Tomorrow (in Green), otherwise the date in Black.||Shows
                  WOC Chip if the task is marked as Waiting on Client.||Time Entry History is sorted by Work Date then Team
                  Member. It only listed last 60 time entries.
                </ExplanationAccordion>
              </Grid>
              <List
                pagination={{
                  count,
                  onPageChange: (page: number) => {
                    setParameters({ ...parameters, page })
                  },
                  onPageSizeChange: (pageSize: number) => {
                    setParameters({ ...parameters, pageSize })
                  },
                  page: parameters.page,
                  pageSize: parameters.pageSize
                }}
                sortedByDescription=''
              ></List>
            </Grid>

            <Grid item xs={12} lg={4} sx={{ mt: 1 }}>
              <Card>
                <CardContent>
                  <Grid item xs={12} container sm={true} alignItems='center' justifyContent='space-between'>
                    <Grid item>
                      <Typography fontWeight='bold' variant='h5'>
                        To Do's
                      </Typography>
                    </Grid>

                    <Grid item>
                      {CAN_ADD_TO_DOS && (
                        <Button endIcon={<Add />} onClick={() => setToDoAddDialogOpen(true)} size='small' variant='text'>
                          ADD
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer>
                      <Table sx={{ width: '100%' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Due Date</TableCell>
                            <TableCell align='center'>To Do</TableCell>
                            <TableCell align='center' colSpan={2}>
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {toDos.map(row => (
                            <TableRow key={row.toDoGuid}>
                              <TableCell component='th' scope='row'>
                                {formatDate(row.dueDate)}
                              </TableCell>

                              <TableCell align='center'>{row.toDo}</TableCell>

                              <TableCell align='center' colSpan={2}>
                                <Grid container alignItems='center' justifyContent='center'>
                                  {!row.isDone && (CAN_MARK_TO_DOS_AS_HIDDEN || row.teamMember?.id === user?.id) && (
                                    <Grid item>
                                      <Button
                                        variant='text'
                                        color='primary'
                                        onClick={() => {
                                          controllers.updateToDoDone(row).then(() => {
                                            getToDos(ToDosParameters)
                                          })
                                        }}
                                      >
                                        Done
                                      </Button>
                                    </Grid>
                                  )}

                                  {CAN_MARK_TO_DOS_AS_HIDDEN && !row.isHidden && (
                                    <Grid item>
                                      <Button
                                        variant='text'
                                        color='primary'
                                        onClick={() => {
                                          controllers.updateToHide(row).then(() => {
                                            getToDos(ToDosParameters)
                                          })
                                        }}
                                      >
                                        Hide
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </CardContent>
              </Card>
              <Grid mt={1}>
                <ExplanationAccordion>
                  Sorted by Due Date.|| Done is only available to the person assigned and the users with “Mark To Do as Hidden”
                  permission.|| Once Done is clicked the Done Button goes away.|| Once Hide is clicked the entire row goes
                  away.|| Ability to Add To Do’s is permission based.
                </ExplanationAccordion>
              </Grid>
              {/* Team Members Ally Card */}
              <Grid mt={1}>
                <Card>
                  <Typography
                    variant='h6'
                    sx={{
                      border: '4px solid white',
                      borderRadius: '1px',
                      backgroundColor: '#8C6751',
                      color: '#fff',
                      //mx: '4px',
                      fontWeight: 'bold',
                      padding: '8px',
                      minWidth: '100%'
                    }}
                  >
                    Team Members
                  </Typography>
                  <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }} colSpan={3}>
                            Name
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {teamMembersForStatusCard.map(teamMember => {
                          // Dynamically set the mailto link for each member
                          const mailToLink = `mailto:${teamMember.email}`
                          const telToLink = `tel:${teamMember.mobilePhone}`
                          const chipSpudversaryText = teamMember.employmentStartDate
                            ? checkSpudversary(teamMember.employmentStartDate)
                            : ''

                          return (
                            <TableRow key={teamMember.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                  <Grid>
                                    <Badge
                                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                      badgeContent=' '
                                      overlap='circular'
                                      sx={{
                                        cursor: 'default',
                                        zIndex: '1115',
                                        bottom: '2px',
                                        right: '3px',
                                        '& .MuiBadge-badge': {
                                          color: '#000',
                                          backgroundColor: teamMember.punchStatus === 'in' ? '#00e676' : '#d32f2f'
                                        }
                                      }}
                                      variant='dot'
                                    >
                                      {teamMember.profilePictureFileName ? (
                                        <Avatar
                                          alt={teamMember.firstName + ' ' + teamMember.lastName}
                                          src={`/api/people/profile/${encodeURIComponent(teamMember.profilePictureFileName!)}`}
                                          sx={{ width: 30, height: 30 }}
                                        />
                                      ) : (
                                        <BoringAvatar
                                          size='30'
                                          square={false}
                                          name={teamMember.firstName + ' ' + teamMember.lastName}
                                          variant='beam'
                                          colors={['#212640', '#D9C7A7', '#BFA380', '#8C6751', '#593E3C']}
                                        />
                                      )}
                                    </Badge>
                                  </Grid>
                                  <Typography variant='body2'>
                                    {teamMember.firstName} {teamMember.lastName}
                                  </Typography>
                                </Box>
                              </TableCell>

                              <TableCell>
                                {checkBday(teamMember.birthMonth ?? 0, teamMember.birthDate ?? 0) && (
                                  <Chip variant='gray'>{checkBday(teamMember.birthMonth!, teamMember.birthDate!)}</Chip>
                                )}
                                {chipSpudversaryText && <Chip variant='gray'>{chipSpudversaryText}</Chip>}
                              </TableCell>

                              <TableCell align='right'>
                                <IconButton href={telToLink}>
                                  <Phone fontSize='small' />
                                </IconButton>

                                <IconButton href={mailToLink}>
                                  <Email fontSize='small' />
                                </IconButton>

                                {/* <IconButton
                                    onClick={() => {
                                      //onClickTextMessage(person)
                                    }}
                                  >
                                    <ChatBubble fontSize='small' />
                                  </IconButton> */}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid mt={1}>
                <ExplanationAccordion>
                  Avatar Status: Indicator: The circle next to the user's avatar reflects their punch status: Green: User is
                  punched in. Red: User is not punched in.|| Name: Displays a list of all users with the "Access Spud ERP"
                  permission.|| Chip Statuses The following chips may appear next to a user’s name:|| B-Day X Day!: Appears if
                  the user's birthday is within 3 days, determined by the Birthdate entered under People and in Human
                  Resources.|| B-Day Today!: Appears if the current date matches the Birthdate entered for the user.||
                  Spud-versary X Day!: Appears if the user’s Spud-versary is within 3 days, determined by the Start Date entered
                  under People and in Human Resources.|| Spud-versary Today!: Appears if the current date matches the Start Date
                  entered for the user.|| Actions Phone Call Action: Clicking the Phone Call Action Icon will initiate a call to
                  the user using the phone number set up for them under People.|| Email Action: Clicking the Email Action Icon
                  will open Outlook and create a new email addressed to the user’s email set up under People.
                </ExplanationAccordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
