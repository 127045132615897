import { apiClient } from '_app'
import { AddressDto, ApiResponse, CompanyDto } from 'dtos'
import { CompaniesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getCompanies = (parameters: CompaniesParameters) =>
  apiClient.get<ApiResponse<CompanyDto[]>>('/api/companies' + URIQuery({ ...parameters })).then(({ data }) => data)

export const getCompanyById = (id: string) =>
  apiClient.get<ApiResponse<CompanyDto>>('/api/companies/' + encodeURIComponent(id)).then(res => res.data)

export const getCompanyAddresses = (id: string) =>
  apiClient.get<ApiResponse<AddressDto[]>>('/api/companies/' + encodeURIComponent(id) + '/addresses').then(res => res.data)

export function createCompany(values: CompanyDto) {
  const data = new FormData()

  data.append('name', values.name)

  if (values.howTheyFoundUs) {
    data.append('howTheyFoundUs', values.howTheyFoundUs)
  }

  if (values.industry) {
    data.append('industry', values.industry)
  }

  if (values.vendor) {
    data.append('vendor', values.vendor.toString())
  }

  if (values.companyCode) {
    data.append('companyCode', values.companyCode)
  }

  if (values.phone) {
    data.append('phone', values.phone)
  }

  if (values.website) {
    data.append('website', values.website)
  }

  if (values.logoFileName) {
    data.append('logoFileName', values.logoFileName)
  }

  if (values.logoFile) {
    data.append('logoFile', values.logoFile)
  }

  if (values.notes) {
    data.append('notes', values.notes)
  }

  if (values.isVendor) {
    data.append('isVendor', values.isVendor.toString())
  }

  if (values.isActive) {
    data.append('isActive', values.isActive.toString())
  }

  return apiClient<ApiResponse<CompanyDto>>({
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: '/api/companies'
  }).then(response => response.data)
}

export const createCompanyAddress = (values: AddressDto, companyId: string) =>
  apiClient
    .post<ApiResponse<AddressDto>>('/api/companies/' + encodeURIComponent(companyId) + '/addresses', values)
    .then(res => res.data)

export function updateCompany(values: CompanyDto) {
  const data = new FormData()

  if (values.id) {
    data.append('id', values.id)
  }

  data.append('name', values.name)

  if (values.campaigns) {
    values.campaigns.forEach((campaign, campaignIndex) => {
      if (campaign.id) {
        data.append(`campaigns[${campaignIndex}].id`, campaign.id)
      }
      if (campaign.name) {
        data.append(`campaigns[${campaignIndex}].name`, campaign.name)
      }
    })
  }

  if (values.howTheyFoundUs) {
    data.append('howTheyFoundUs', values.howTheyFoundUs)
  }

  if (values.industry) {
    data.append('industry', values.industry)
  }

  if (values.vendor) {
    data.append('vendor', values.vendor.toString())
  }

  if (values.companyCode) {
    data.append('companyCode', values.companyCode)
  }

  if (values.phone) {
    data.append('phone', values.phone)
  }

  if (values.website) {
    data.append('website', values.website)
  }

  if (values.logoFileName) {
    data.append('logoFileName', values.logoFileName)
  }

  if (values.logoFile) {
    data.append('logoFile', values.logoFile)
  }

  if (values.notes) {
    data.append('notes', values.notes)
  }

  if (values.isVendor) {
    data.append('isVendor', values.isVendor.toString())
  }

  if (values.isActive) {
    data.append('isActive', values.isActive.toString())
  }

  return apiClient<ApiResponse<CompanyDto>>({
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'put',
    url: '/api/companies'
  }).then(response => response.data)
}
