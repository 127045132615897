import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  TextField
} from '@mui/material'
import { useAuth } from 'context'
import * as controllers from 'controllers'
import {
  PersonDto,
  WorkOrderDto,
  WorkOrderReleaseDto,
  WorkOrderReleaseTaskDto,
  WorkOrderReleaseTaskTypeDto,
  workOrderReleaseTaskValidationSchema
} from 'dtos'
import { Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { getReleaseNumber } from 'utils'

interface WorkOrderReleaseTaskAddEditDialogProps {
  initialValues: WorkOrderReleaseTaskDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: WorkOrderReleaseTaskDto) => void
  open: boolean
  workOrder?: WorkOrderDto
  workOrderReleaseTasks?: WorkOrderReleaseTaskDto[]
}

export default function WorkOrderReleaseTaskAddEditDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open,
  workOrder,
  workOrderReleaseTasks
}: WorkOrderReleaseTaskAddEditDialogProps) {
  const {
    permissions: { WORK_ORDER_TASKS_ADD_EDIT, WORK_ORDER_TASKS_CAN_ASSIGN }
  } = useAuth()
  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  // "workOrderRelease" is used to filter out invalid Work Order Release Task Types.
  const [workOrderRelease, setWorkOrderRelease] = useState<WorkOrderReleaseDto | null>(null)
  const [workOrderReleases, setWorkOrderReleases] = useState<WorkOrderReleaseDto[]>([])
  const [workOrderReleaseTaskTypes, setWorkOrderReleaseTaskTypes] = useState<WorkOrderReleaseTaskTypeDto[]>([])

  // Get team members and task types.
  useEffect(() => {
    if (open) {
      controllers.getPeople({ page: 0, pageSize: 2000, permission: 'TASKS', statuses: ['isActive'] }).then(response => {
        setTeamMembers(response.value)
      })

      controllers.getWorkOrderReleaseTaskTypes({ page: 0, pageSize: 2000 }).then(response => {
        setWorkOrderReleaseTaskTypes(response.value)
      })
    }
  }, [open])

  useEffect(() => {
    if (workOrder && workOrder.id) {
      controllers.getWorkOrderReleasesByWorkOrderId({ page: 0, pageSize: 2000 }, workOrder.id).then(response => {
        setWorkOrderReleases(response.value)
      })
    }
  }, [workOrder])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={workOrderReleaseTaskValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{(values.id ? 'Edit' : 'Add') + ' Task'}</DialogTitle>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option =>
                        getReleaseNumber(option as WorkOrderReleaseDto)
                          ? `${getReleaseNumber(option as WorkOrderReleaseDto)} - ${option.releaseTitle}`
                          : `${option.releaseTitle}`
                      }
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('workOrderRelease', value)
                        setFieldValue('workOrderReleaseTaskType', null)
                      }}
                      options={workOrderReleases
                        .slice()
                        .filter(workOrderRelease => !workOrderRelease.isReleaseComplete)
                        .sort((a, b) => Number(a.releaseNumber) - Number(b.releaseNumber))
                        .map(workOrderRelease => ({
                          id: workOrderRelease.id,
                          releaseTitle: workOrderRelease.releaseTitle,
                          releaseNumber: workOrderRelease.releaseNumber,
                          isReleaseNumberNumeric: workOrderRelease.isReleaseNumberNumeric
                        }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.workOrderRelease && errors.workOrderRelease)}
                          helperText={touched.workOrderRelease && errors.workOrderRelease}
                          label='Release'
                          name='workOrderRelease'
                        />
                      )}
                      value={
                        values.workOrderRelease
                          ? {
                              id: values.workOrderRelease.id,
                              releaseTitle: values.workOrderRelease.releaseTitle,
                              releaseNumber: values.workOrderRelease.releaseNumber,
                              isReleaseNumberNumeric: values.workOrderRelease.isReleaseNumberNumeric
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name!}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('workOrderReleaseTaskType', value)
                        setFieldValue('workToBeDone', value?.name)
                      }}
                      options={workOrderReleaseTaskTypes
                        .sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
                        .map(workOrderReleaseTaskType => ({
                          id: workOrderReleaseTaskType.id,
                          name: workOrderReleaseTaskType.name,
                          specialIdentifier: workOrderReleaseTaskType.specialIdentifier
                        }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.workOrderReleaseTaskType && errors.workOrderReleaseTaskType)}
                          helperText={touched.workOrderReleaseTaskType && errors.workOrderReleaseTaskType}
                          label='Task Name'
                          name='workOrderReleaseTaskType'
                        />
                      )}
                      value={
                        values.workOrderReleaseTaskType
                          ? {
                              id: values.workOrderReleaseTaskType.id,
                              name: values.workOrderReleaseTaskType.name,
                              specialIdentifier: values.workOrderReleaseTaskType.specialIdentifier
                            }
                          : null
                      }
                    />
                  </Grid>

                  {WORK_ORDER_TASKS_ADD_EDIT && (
                    <Grid item xs={12}>
                      <Autocomplete
                        disabled={isLoading || isSubmitting}
                        disableCloseOnSelect={!Boolean(values.id)}
                        // @ts-ignore
                        getOptionLabel={option => option.firstName + ' ' + option.lastName}
                        onBlur={handleBlur}
                        onChange={(_e, value) => {
                          if (values.id) {
                            setFieldValue('responsible', [value])
                          } else {
                            setFieldValue('responsible', value)
                          }
                        }}
                        options={teamMembers
                          .filter(teamMember => !values.responsible?.some(company => company.id === teamMember.id))
                          .map(teamMember => ({
                            id: teamMember.id,
                            firstName: teamMember.firstName,
                            lastName: teamMember.lastName
                          }))}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(touched.responsible && errors.responsible)}
                            // There shouldn't ever be errors.responsible values from the individual teamMember objects
                            // so it should only ever be a string
                            helperText={touched.responsible && (errors.responsible as string)}
                            label='Responsible'
                          />
                        )}
                        multiple={!Boolean(values.id)}
                        value={
                          values.id
                            ? values.responsible[0]
                              ? {
                                  id: values.responsible[0].id,
                                  firstName: values.responsible[0].firstName,
                                  lastName: values.responsible[0].lastName
                                }
                              : undefined
                            : values.responsible
                            ? values.responsible.map(teamMember => ({
                                id: teamMember.id,
                                firstName: teamMember.firstName,
                                lastName: teamMember.lastName
                              }))
                            : []
                        }
                      />

                      {!Boolean(values.id) && (
                        <Link
                          onClick={() => {
                            if (!(isLoading || isSubmitting)) {
                              if (values.responsible.length > 0) {
                                setFieldValue('responsible', [])
                              } else {
                                setFieldValue('responsible', teamMembers)
                              }
                            }
                          }}
                          sx={{ cursor: 'pointer', ml: 2 }}
                        >
                          {values.responsible.length > 0 ? 'Deselect All' : 'Select All'}
                        </Link>
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.workToBeDone && errors.workToBeDone)}
                      helperText={touched.workToBeDone && errors.workToBeDone}
                      label='Work To Be Done'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='workToBeDone'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.workToBeDone || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.adminNotes && errors.adminNotes)}
                      helperText={touched.adminNotes && errors.adminNotes}
                      label='Admin Notes'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='adminNotes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adminNotes || ''}
                    />
                  </Grid>
                  {WORK_ORDER_TASKS_ADD_EDIT && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading || isSubmitting}
                        error={Boolean(touched.estimatedHours && errors.estimatedHours)}
                        helperText={touched.estimatedHours && errors.estimatedHours}
                        label='Estimated Hours'
                        name='estimatedHours'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='number'
                        value={values.estimatedHours}
                      />
                    </Grid>
                  )}

                  {/* taskCanBeClosed is only relevant when creating a new task */}
                  {!values.id && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.taskCanBeClosed}
                            disabled={isLoading || isSubmitting}
                            name='taskCanBeClosed'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.taskCanBeClosed}
                          />
                        }
                        label='Task Can Be Closed'
                      />
                    </Grid>
                  )}

                  {!values.id && values.taskCanBeClosed && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading}
                        error={Boolean(touched.hoursWorked && errors.hoursWorked)}
                        helperText={touched.hoursWorked && errors.hoursWorked}
                        label='Hours Worked'
                        name='hoursWorked'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='number'
                        value={values.hoursWorked}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isWaitingOnClient}
                        disabled={isLoading || isSubmitting}
                        name='isWaitingOnClient'
                        onBlur={handleBlur}
                        // Todo this was setting value to 'On' instead of 'true'
                        onChange={e => setFieldValue('isWaitingOnClient', !values.isWaitingOnClient)}
                        value={values.isWaitingOnClient}
                      />
                    }
                    label='Waiting on Client'
                  />
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
