import { apiClient } from '_app'
import { ApiResponse, PersonAccountSetupDto, PersonDto, PersonHumanResourcesDto, PersonPunchStatusDto } from 'dtos'
import { url } from 'inspector'
import { PeopleParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getPeople = (parameters: PeopleParameters) =>
  apiClient.get<ApiResponse<PersonDto[]>>('/api/people' + URIQuery({ ...parameters })).then(({ data }) => data)

export const getPeoplePunchStatuses = () =>
  apiClient.get<ApiResponse<PersonPunchStatusDto[]>>('/api/people/punch-statuses').then(({ data }) => data)

export const getPersonById = (id: string) =>
  apiClient.get<ApiResponse<PersonDto>>('/api/people/' + encodeURIComponent(id)).then(({ data }) => data)

export function createPerson(values: PersonDto) {
  const data = new FormData()

  data.append('firstName', values.firstName)

  if (values.company) {
    if (values.company.id) {
      data.append('company.id', values.company.id)
    }
  }

  if (values.lastName) {
    data.append('lastName', values.lastName)
  }
  if (values.title) {
    data.append('title', values.title)
  }
  if (values.email) {
    data.append('email', values.email)
  }
  if (values.textEmail) {
    data.append('textEmail', values.textEmail)
  }
  if (values.mobilePhone) {
    data.append('mobilePhone', values.mobilePhone)
  }
  if (values.workPhone) {
    data.append('workPhone', values.workPhone)
  }
  if (values.notes) {
    data.append('notes', values.notes)
  }
  if (values.allowUserToSignIn) {
    data.append('allowUserToSignIn', values.allowUserToSignIn.toString())
  }
  if (values.employmentStartDate) {
    data.append('employmentStartDate', values.employmentStartDate.toString())
  }
  if (values.employmentEndDate) {
    data.append('employmentEndDate', values.employmentEndDate.toString())
  }
  if (values.birthDate) {
    data.append('birthDate', values.birthDate.toString())
  }
  if (values.birthMonth) {
    data.append('birthMonth', values.birthMonth.toString())
  }
  if (values.profileFile) {
    data.append('profileFile', values.profileFile)
  }
  if (values.profilePictureFileName) {
    data.append('profilePictureFileName', values.profilePictureFileName)
  }
  if (values.isActive) {
    data.append('isActive', values.isActive.toString())
  }
  if (values.isRecruitingCandidate) {
    data.append('isRecruitingCandidate', values.isRecruitingCandidate.toString())
  }
  if (values.isUser) {
    data.append('isUser', values.isUser.toString())
  }

  return apiClient<ApiResponse<PersonDto>>({
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: '/api/people'
  }).then(response => response.data)
}

export function updatePerson(values: PersonDto) {
  const data = new FormData()

  if (values.id) {
    data.append('id', values.id)
  }

  if (values.company) {
    if (values.company.id) {
      data.append('company.id', values.company.id)
    }
    if (values.company.name) {
      data.append('company.name', values.company.name)
    }
  }

  data.append('firstName', values.firstName)

  if (values.lastName) {
    data.append('lastName', values.lastName)
  }
  if (values.title) {
    data.append('title', values.title)
  }
  if (values.email) {
    data.append('email', values.email)
  }
  if (values.textEmail) {
    data.append('textEmail', values.textEmail)
  }
  if (values.mobilePhone) {
    data.append('mobilePhone', values.mobilePhone)
  }
  if (values.workPhone) {
    data.append('workPhone', values.workPhone)
  }
  if (values.notes) {
    data.append('notes', values.notes)
  }
  if (values.allowUserToSignIn) {
    data.append('allowUserToSignIn', values.allowUserToSignIn.toString())
  }
  if (values.employmentStartDate) {
    data.append('employmentStartDate', values.employmentStartDate.toString())
  }
  if (values.employmentEndDate) {
    data.append('employmentEndDate', values.employmentEndDate.toString())
  }
  if (values.birthDate) {
    data.append('birthDate', values.birthDate.toString())
  }
  if (values.birthMonth) {
    data.append('birthMonth', values.birthMonth.toString())
  }
  if (values.profileFile) {
    data.append('profileFile', values.profileFile)
  }
  if (values.profilePictureFileName) {
    data.append('profilePictureFileName', values.profilePictureFileName)
  }
  if (values.isActive) {
    data.append('isActive', values.isActive.toString())
  }
  if (values.isRecruitingCandidate) {
    data.append('isRecruitingCandidate', values.isRecruitingCandidate.toString())
  }
  if (values.isUser) {
    data.append('isUser', values.isUser.toString())
  }

  return apiClient<ApiResponse<PersonDto>>({
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'put',
    url: '/api/people'
  }).then(response => response.data)
}
export const getAccountSetup = (id: string) =>
  apiClient
    .get<ApiResponse<PersonAccountSetupDto>>('/api/people/' + encodeURIComponent(id) + '/account-setup')
    .then(res => res.data)

export const updateAccountSetup = (id: string, values: PersonAccountSetupDto) =>
  apiClient
    .put<ApiResponse<PersonAccountSetupDto>>('/api/people/' + encodeURIComponent(id) + '/account-setup', values)
    .then(res => res.data)

export const getHumanResources = (id: string) =>
  apiClient
    .get<ApiResponse<PersonHumanResourcesDto>>('/api/people/' + encodeURIComponent(id) + '/human-resources')
    .then(res => res.data)

export const updateHumanResources = (id: string, values: PersonHumanResourcesDto) =>
  apiClient
    .put<ApiResponse<PersonHumanResourcesDto>>('/api/people/' + encodeURIComponent(id) + '/human-resources', values)
    .then(res => res.data)
