import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ExplanationAccordion } from 'components/_template/accordion'
import { PersonDto, WorkOrderDto, WorkOrderReleaseDto, workOrderReleaseValidationSchema } from 'dtos'
import { Formik, FormikValues } from 'formik'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import * as api from 'controllers'
import { formatDate } from 'utils'

interface WorkOrderReleaseAddEditDialogProps {
  initialValues: WorkOrderReleaseDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: WorkOrderReleaseDto) => void
  open: boolean
  /**
   * "workOrder" can be used to determine what release types to display based on the
   * work order's work order type.
   */
  workOrder?: WorkOrderDto
  /**
   * "workOrderReleases" can be used to determine what release types to display
   * (Do not show any work order release types that already exist on the work order.)
   */
  workOrderReleases?: WorkOrderReleaseDto[]
}

export default function WorkOrderReleaseAddEditDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open,
  workOrder,
  workOrderReleases
}: WorkOrderReleaseAddEditDialogProps) {
  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])

  useEffect(() => {
    api
      .getPeople({ page: 0, pageSize: 999, permission: 'TASKS', statuses: ['isUser'] })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .catch(() => {})
  }, [])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={workOrderReleaseValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          console.log(values)

          const calcReleaseHoursSum = (values: FormikValues): number => {
            return (
              (parseFloat(values.designPhaseHoursAllowed || '') || 0) +
              (parseFloat(values.definePhaseHoursAllowed || '') || 0) +
              (parseFloat(values.developmentPhaseHoursAllowed || '') || 0) +
              (parseFloat(values.deliverPhaseHoursAllowed || '') || 0)
            )
          }

          const calcMinDate = (values: FormikValues): string | '' => {
            const dates = [
              new Date(values.designPhaseStartDate || ''),
              new Date(values.definePhaseStartDate || ''),
              new Date(values.developmentPhaseStartDate || ''),
              new Date(values.deliverPhaseStartDate || '')
            ]
            const validDates = dates.filter(date => !isNaN(date.getTime()))
            if (validDates.length > 0) {
              const minTimeStamp = Math.min(...validDates.map(date => date.getTime()))
              return formatDate(new Date(minTimeStamp))
            } else {
              return 'No valid date entered.'
            }
          }

          const calcMaxDate = (values: FormikValues): string | '' => {
            const dates = [
              new Date(values.designPhaseDuetDate || ''),
              new Date(values.definePhaseDueDate || ''),
              new Date(values.developmentPhaseDueDate || ''),
              new Date(values.deliverPhaseDueDate || '')
            ]
            const validDates = dates.filter(date => !isNaN(date.getTime()))
            if (validDates.length > 0) {
              const minTimeStamp = Math.max(...validDates.map(date => date.getTime()))
              return formatDate(new Date(minTimeStamp))
            } else {
              return 'No valid date entered.'
            }
          }

          const handleFieldNulling = (
            fieldName1: string,
            fieldName2: string,
            fieldName3: string,
            fieldName4: string,
            value: number,
            setFieldValue: (field: string, value: number | undefined) => void
          ) => {
            if (value === 0) {
              setFieldValue(fieldName1, value)
              setFieldValue(fieldName2, undefined)
              setFieldValue(fieldName3, undefined)
              setFieldValue(fieldName4, undefined)
            } else {
              setFieldValue(fieldName1, value)
            }
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Release</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.releaseTitle && errors.releaseTitle)}
                      helperText={touched.releaseTitle && errors.releaseTitle}
                      label='Release Title'
                      name='releaseTitle'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.releaseTitle || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Release Target Completion'
                      name='signOffDueDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('signOffDueDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('signOffDueDate', undefined)
                        }
                      }}
                      value={values.signOffDueDate ? moment(values.signOffDueDate) : null}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.signOffDueDate && errors.signOffDueDate),
                          helperText: touched.signOffDueDate && errors.signOffDueDate,
                          onBlur: handleBlur
                        },
                        field: { clearable: true }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      name='notes'
                      multiline
                      rows={4}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes || ''}
                    />
                  </Grid>

                  {workOrder?.workOrderType?.specialIdentifier === 'PROJECT' && (
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='h6' align='center'>
                          Define Phase
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading || isSubmitting}
                          error={Boolean(touched.definePhaseHoursAllowed && errors.definePhaseHoursAllowed)}
                          helperText={touched.definePhaseHoursAllowed && errors.definePhaseHoursAllowed}
                          label='Hours Allowed'
                          name='definePhaseHoursAllowed'
                          type='number'
                          value={values.definePhaseHoursAllowed}
                          onBlur={handleBlur}
                          onChange={e => {
                            if (e.target.value === undefined) {
                              setFieldValue('definePhaseHoursAllowed', e.target.value)
                            } else {
                              handleFieldNulling(
                                'definePhaseHoursAllowed',
                                'definePhaseDefinedBy',
                                'definePhaseStartDate',
                                'definePhaseDueDate',
                                Number(e.target.value),
                                setFieldValue
                              )
                            }
                          }}
                          inputProps={{
                            min: '0',
                            step: '1'
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={values.definePhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          error={Boolean(touched.definePhaseDefinedBy && errors.definePhaseDefinedBy)}
                          helperText={touched.definePhaseDefinedBy && errors.definePhaseDefinedBy}
                          label='Defined By'
                          name='definePhaseDefinedBy'
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue('definePhaseDefinedBy', JSON.parse(e.target.value))
                          }}
                          select
                          sx={{ minWidth: 240 }}
                          value={values.definePhaseDefinedBy ? JSON.stringify(values.definePhaseDefinedBy) : undefined}
                        >
                          {teamMembers.map(teamMember => (
                            <MenuItem key={teamMember.id} value={JSON.stringify(teamMember.id)}>
                              {teamMember.firstName} {teamMember.lastName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.definePhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Start Date'
                          name='definePhaseStartDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('definePhaseStartDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('definePhaseStartDate', undefined)
                            }
                          }}
                          value={values.definePhaseStartDate ? moment(values.definePhaseStartDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.definePhaseStartDate && errors.definePhaseStartDate),
                              helperText: touched.definePhaseStartDate && errors.definePhaseStartDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.definePhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Due Date'
                          name='definePhaseDueDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('definePhaseDueDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('definePhaseDueDate', undefined)
                            }
                          }}
                          value={values.definePhaseDueDate ? moment(values.definePhaseDueDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.definePhaseDueDate && errors.definePhaseDueDate),
                              helperText: touched.definePhaseDueDate && errors.definePhaseDueDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='h6' align='center'>
                          Design Phase
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading || isSubmitting}
                          error={Boolean(touched.designPhaseHoursAllowed && errors.designPhaseHoursAllowed)}
                          helperText={touched.designPhaseHoursAllowed && errors.designPhaseHoursAllowed}
                          label='Hours Allowed'
                          name='designPhaseHoursAllowed'
                          type='number'
                          value={values.designPhaseHoursAllowed}
                          onBlur={handleBlur}
                          onChange={e =>
                            handleFieldNulling(
                              'designPhaseHoursAllowed',
                              'designPhaseDefinedBy',
                              'designPhaseStartDate',
                              'designPhaseDueDate',
                              Number(e.target.value),
                              setFieldValue
                            )
                          }
                          inputProps={{
                            min: '0',
                            step: '1'
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={values.designPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          error={Boolean(touched.designPhaseDefinedBy && errors.designPhaseDefinedBy)}
                          helperText={touched.designPhaseDefinedBy && errors.designPhaseDefinedBy}
                          label='Defined By'
                          name='designPhaseDefinedBy'
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue('designPhaseDefinedBy', JSON.parse(e.target.value))
                          }}
                          select
                          sx={{ minWidth: 240 }}
                          value={values.designPhaseDefinedBy ? JSON.stringify(values.designPhaseDefinedBy) : undefined}
                        >
                          {teamMembers.map(teamMember => (
                            <MenuItem key={teamMember.id} value={JSON.stringify(teamMember.id)}>
                              {teamMember.firstName} {teamMember.lastName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.designPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Start Date'
                          name='designPhaseStartDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('designPhaseStartDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('designPhaseStartDate', undefined)
                            }
                          }}
                          value={values.designPhaseStartDate ? moment(values.designPhaseStartDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.designPhaseStartDate && errors.designPhaseStartDate),
                              helperText: touched.designPhaseStartDate && errors.designPhaseStartDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.designPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Due Date'
                          name='designPhaseDueDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('designPhaseDueDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('designPhaseDueDate', undefined)
                            }
                          }}
                          value={values.designPhaseDueDate ? moment(values.designPhaseDueDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.designPhaseDueDate && errors.designPhaseDueDate),
                              helperText: touched.designPhaseDueDate && errors.designPhaseDueDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='h6' align='center'>
                          Development Phase
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading || isSubmitting}
                          error={Boolean(touched.developmentPhaseHoursAllowed && errors.developmentPhaseHoursAllowed)}
                          helperText={touched.developmentPhaseHoursAllowed && errors.developmentPhaseHoursAllowed}
                          label='Hours Allowed'
                          name='developmentPhaseHoursAllowed'
                          type='number'
                          value={values.developmentPhaseHoursAllowed}
                          onBlur={handleBlur}
                          onChange={e =>
                            handleFieldNulling(
                              'developmentPhaseHoursAllowed',
                              'developmentPhaseDefinedBy',
                              'developmentPhaseStartDate',
                              'developmentPhaseDueDate',
                              Number(e.target.value),
                              setFieldValue
                            )
                          }
                          inputProps={{
                            min: '0',
                            step: '1'
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={values.developmentPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          error={Boolean(touched.developmentPhaseDefinedBy && errors.developmentPhaseDefinedBy)}
                          helperText={touched.developmentPhaseDefinedBy && errors.developmentPhaseDefinedBy}
                          label='Defined By'
                          name='developmentPhaseDefinedBy'
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue('developmentPhaseDefinedBy', JSON.parse(e.target.value))
                          }}
                          select
                          sx={{ minWidth: 240 }}
                          value={
                            values.developmentPhaseDefinedBy ? JSON.stringify(values.developmentPhaseDefinedBy) : undefined
                          }
                        >
                          {teamMembers.map(teamMember => (
                            <MenuItem key={teamMember.id} value={JSON.stringify(teamMember.id)}>
                              {teamMember.firstName} {teamMember.lastName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.developmentPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Start Date'
                          name='developmentPhaseStartDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('developmentPhaseStartDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('developmentPhaseStartDate', undefined)
                            }
                          }}
                          value={values.developmentPhaseStartDate ? moment(values.developmentPhaseStartDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.developmentPhaseStartDate && errors.developmentPhaseStartDate),
                              helperText: touched.developmentPhaseStartDate && errors.developmentPhaseStartDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.developmentPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Due Date'
                          name='developmentPhaseDueDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('developmentPhaseDueDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('developmentPhaseDueDate', undefined)
                            }
                          }}
                          value={values.developmentPhaseDueDate ? moment(values.developmentPhaseDueDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.developmentPhaseDueDate && errors.developmentPhaseDueDate),
                              helperText: touched.developmentPhaseDueDate && errors.developmentPhaseDueDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='h6' align='center'>
                          Delivered Phase
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading || isSubmitting}
                          error={Boolean(touched.deliverPhaseHoursAllowed && errors.deliverPhaseHoursAllowed)}
                          helperText={touched.deliverPhaseHoursAllowed && errors.deliverPhaseHoursAllowed}
                          label='Hours Allowed'
                          name='deliverPhaseHoursAllowed'
                          type='number'
                          value={values.deliverPhaseHoursAllowed}
                          onBlur={handleBlur}
                          onChange={e =>
                            handleFieldNulling(
                              'deliverPhaseHoursAllowed',
                              'deliverPhaseDefinedBy',
                              'deliverPhaseStartDate',
                              'deliverPhaseDueDate',
                              Number(e.target.value),
                              setFieldValue
                            )
                          }
                          inputProps={{
                            min: '0',
                            step: '1'
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={values.deliverPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          error={Boolean(touched.deliverPhaseDefinedBy && errors.deliverPhaseDefinedBy)}
                          helperText={touched.deliverPhaseDefinedBy && errors.deliverPhaseDefinedBy}
                          label='Defined By'
                          name='deliverPhaseDefinedBy'
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue('deliverPhaseDefinedBy', JSON.parse(e.target.value))
                          }}
                          select
                          sx={{ minWidth: 240 }}
                          value={values.deliverPhaseDefinedBy ? JSON.stringify(values.deliverPhaseDefinedBy) : undefined}
                        >
                          {teamMembers.map(teamMember => (
                            <MenuItem key={teamMember.id} value={JSON.stringify(teamMember.id)}>
                              {teamMember.firstName} {teamMember.lastName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.deliverPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Start Date'
                          name='deliverPhaseStartDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('deliverPhaseStartDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('deliverPhaseStartDate', undefined)
                            }
                          }}
                          value={values.deliverPhaseStartDate ? moment(values.deliverPhaseStartDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.deliverPhaseStartDate && errors.deliverPhaseStartDate),
                              helperText: touched.deliverPhaseStartDate && errors.deliverPhaseStartDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={values.deliverPhaseHoursAllowed === 0 || isLoading || isSubmitting}
                          label='Due Date'
                          name='deliverPhaseDueDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('deliverPhaseDueDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('deliverPhaseDueDate', undefined)
                            }
                          }}
                          value={values.deliverPhaseDueDate ? moment(values.deliverPhaseDueDate) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.deliverPhaseDueDate && errors.deliverPhaseDueDate),
                              helperText: touched.deliverPhaseDueDate && errors.deliverPhaseDueDate,
                              onBlur: handleBlur
                            },
                            field: { clearable: true }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.productionDeploymentRequired}
                              disabled={isLoading || isSubmitting}
                              name='productionDeploymentRequired'
                              onBlur={handleBlur}
                              onChange={e =>
                                setFieldValue('productionDeploymentRequired', !values.productionDeploymentRequired)
                              }
                              value={values.productionDeploymentRequired}
                            />
                          }
                          label='Production Deployment Required'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='h6' align='center'>
                          Summary
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='body1' align='center'>
                          Release Hours Allowed: {calcReleaseHoursSum(values)}
                        </Typography>
                        <Typography variant='body1' align='center'>
                          Release Start Date: {calcMinDate(values)}
                        </Typography>
                        <Typography variant='body1' align='center'>
                          Release Due Date: {calcMaxDate(values)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {/* <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Use Case Verbally Approved by Client'
                      name='useCaseVerballyApprovedByClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('useCaseVerballyApprovedByClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('useCaseVerballyApprovedByClientDate', undefined)
                        }
                      }}
                      value={
                        values.useCaseVerballyApprovedByClientDate ? moment(values.useCaseVerballyApprovedByClientDate) : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Design Spec Verbally Approved by Client'
                      name='designSpecVerballyApprovedByClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('designSpecVerballyApprovedByClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('designSpecVerballyApprovedByClientDate', undefined)
                        }
                      }}
                      value={
                        values.designSpecVerballyApprovedByClientDate
                          ? moment(values.designSpecVerballyApprovedByClientDate)
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Design Spec Sign Off Sent to Client'
                      name='designSpecSignOffSentToClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('designSpecSignOffSentToClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('designSpecSignOffSentToClientDate', undefined)
                        }
                      }}
                      value={values.designSpecSignOffSentToClientDate ? moment(values.designSpecSignOffSentToClientDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Design Spec Signed Off by Client'
                      name='designSpecSignedOffByClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('designSpecSignedOffByClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('designSpecSignedOffByClientDate', undefined)
                        }
                      }}
                      value={values.designSpecSignedOffByClientDate ? moment(values.designSpecSignedOffByClientDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Development Complete'
                      name='developmentCompleteDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('developmentCompleteDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('developmentCompleteDate', undefined)
                        }
                      }}
                      value={values.developmentCompleteDate ? moment(values.developmentCompleteDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Staging Tested by Spud'
                      name='stagingTestedBySpudDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('stagingTestedBySpudDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('stagingTestedBySpudDate', undefined)
                        }
                      }}
                      value={values.stagingTestedBySpudDate ? moment(values.stagingTestedBySpudDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Release Sign Off Sent to Client'
                      name='releaseSignOffSentToClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('releaseSignOffSentToClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('releaseSignOffSentToClientDate', undefined)
                        }
                      }}
                      value={values.releaseSignOffSentToClientDate ? moment(values.releaseSignOffSentToClientDate) : null}
                    />
                  </Grid> */}

                  {values.id && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isReleaseComplete}
                            disabled={isLoading || isSubmitting}
                            name='isReleaseComplete'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.isReleaseComplete}
                          />
                        }
                        label='Release is Complete'
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Release Target Completion Date is required. This date is used to sort the releases. ||Based on Work Order
                      Type the following is displayed: ||- Ad Hoc Support shows Release Title, Release Target Completion, Notes
                      and has the ability to be marked Complete ||- Network Support shows Release Title, Release Target
                      Completion, Notes and has the ability to be marked Complete ||- Software Project shows Phases and
                      associated required information ||If this part of a project and requires Network Support put the
                      associated information under the Development Phase. ||If the Release is complete, or to the point where it
                      has been pushed to production, or will be pushed to production as part of another Release (make a note of
                      that) and toggle Release is Complete to True. ||If a Phase is not required for this Release enter 0 for
                      Hours Allowed and the remaining fields for that phase will be set to Null. Otherwise, all fields are
                      required. ||Summary is based on the sum of all Hours Allowed, earliest Start Date and furthest out Due
                      Date for this Release
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
