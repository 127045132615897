import { WorkOrderDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { idIsNotNullUndefinedOrNew, useDebounce } from 'utils'
import * as controllers from 'controllers'
import { Grid } from '@mui/material'
import WorkOrderDetailsCard from './WorkOrderDetailsCard'
import WorkOrderReleasesCard from './WorkOrderReleasesCard'
import WorkOrderReleaseTasksCard from './WorkOrderReleaseTasksCard'
import WorkOrderInvoicesCard from './WorkOrderInvoicesCard'
import { useAuth, useLayout } from 'context'

export default function WorkOrdersId() {
  const {
    permissions: {
      WORK_ORDER_INVOICES_ADD,
      WORK_ORDER_INVOICES_EDIT,
      WORK_ORDER_INVOICES_READ_ONLY,
      WORK_ORDER_RELEASES_ADD_EDIT,
      WORK_ORDER_RELEASES_READ_ONLY,
      WORK_ORDER_TASKS_ADD_EDIT,
      WORK_ORDER_TASKS_READ_ONLY
    }
  } = useAuth()
  const { id } = useParams()
  const { setIsLayoutLoading, setTitle } = useLayout()

  const [isGettingWorkOrder, setIsGettingWorkOrder] = useState<boolean>(false)
  const [workOrder, setWorkOrder] = useState<WorkOrderDto>(new WorkOrderDto())

  useEffect(() => {
    setTitle('Work Order Details')
  }, [])

  useEffect(() => {
    getWorkOrder()
  }, [id])

  const getWorkOrder = useDebounce(() => {
    if (idIsNotNullUndefinedOrNew({ id })) {
      setIsLayoutLoading(true)
      controllers
        .getWorkOrderById(id!)
        .then(response => {
          setWorkOrder(response.value)
        })
        .finally(() => {
          setIsLayoutLoading(false)
        })
    }
  }, 300)

  return (
    <Grid container spacing={2} alignItems='flex-start'>
      <Grid item xs={12}>
        <WorkOrderDetailsCard workOrder={workOrder} setWorkOrder={setWorkOrder} />
      </Grid>

      {(WORK_ORDER_RELEASES_ADD_EDIT || WORK_ORDER_RELEASES_READ_ONLY) && (
        <Grid item xs={12} lg={4}>
          <WorkOrderReleasesCard workOrder={workOrder} getWorkOrder={getWorkOrder} />
        </Grid>
      )}

      {(WORK_ORDER_TASKS_ADD_EDIT || WORK_ORDER_TASKS_READ_ONLY) && (
        <Grid item xs={12} lg={4}>
          <WorkOrderReleaseTasksCard workOrder={workOrder} getWorkOrder={getWorkOrder} />
        </Grid>
      )}

      {(WORK_ORDER_INVOICES_ADD || WORK_ORDER_INVOICES_EDIT || WORK_ORDER_INVOICES_READ_ONLY) && (
        <Grid item xs={12} lg={4}>
          <WorkOrderInvoicesCard workOrder={workOrder} getWorkOrder={getWorkOrder} />
        </Grid>
      )}
    </Grid>
  )
}
