export function checkSpudversary(startDate: string) {
  const targetDate = new Date(startDate.replace(/-/g, '/'))
  // Get the current date
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  const currentYear = currentDate.getFullYear()
  targetDate.setFullYear(currentYear)
  // Calculate the difference in time (milliseconds)
  const timeDifference = targetDate.getTime() - currentDate.getTime()
  // Calculate the difference in days
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))

  if (daysDifference === 0) {
    return 'Spud-versary is Today!'
  } else if (daysDifference === 1 || daysDifference === -365) {
    return 'Spud-versary in 1 day'
  } else if (daysDifference === 2 || daysDifference === -364) {
    return 'Spud-versary in 2 days'
  } else if (daysDifference === 3 || daysDifference === -363) {
    return 'Spud-versary in 3 days'
  } else {
    return ''
  }
}
