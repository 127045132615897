import { useLoadingState } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as controllers from 'controllers'
import { Button, Divider, Grid } from '@mui/material'
import SecretGroupHeroCard from '../SecretGroupHeroCard'
import { SecretGroupDto } from 'dtos'
import { useAuth, useLayout } from 'context'
import SecretGroupAddEditDialog from './SecretGroupAddEditDialog'
import { enqueueSnackbar } from 'notistack'
import { ArrowBack, Edit } from '@mui/icons-material'
import { Masonry } from '@mui/lab'
import SecretsTable from './SecretsTable'

export default function SecretsId() {
  const { permissions } = useAuth()
  const navigate = useNavigate()
  const { id } = useParams()
  const layout = useLayout()
  const [loadingState, setLoadingState] = useLoadingState({
    isGettingSecretGroup: false,
    isUpdatingSecretGroup: false
  })
  const [secretGroup, setSecretGroup] = useState<SecretGroupDto>(new SecretGroupDto())
  const [isSecretGroupAddEditDialogOpen, setIsSecretGroupAddEditDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    if (id && id !== 'undefined' && id !== 'new') {
      layout.setIsLayoutLoading(true)
      controllers
        .getSecretGroupById(id)
        .then(response => {
          setSecretGroup(response.value)
        })
        .finally(() => {
          layout.setIsLayoutLoading(false)
        })
    }
  }, [id])

  return (
    <>
      <SecretGroupAddEditDialog
        initialValues={secretGroup}
        loading={loadingState.isUpdatingSecretGroup}
        onClose={() => {
          setIsSecretGroupAddEditDialogOpen(false)
        }}
        onSave={values => {
          setLoadingState('isUpdatingSecretGroup', true)
          controllers
            .updateSecretGroup(values)
            .then(response => {
              enqueueSnackbar('Secret Group Saved Successfully!', { variant: 'success' })
              setIsSecretGroupAddEditDialogOpen(false)
              setSecretGroup(response.value)
            })
            .finally(() => {
              setLoadingState('isUpdatingSecretGroup', false)
            })
        }}
        open={isSecretGroupAddEditDialogOpen}
      />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SecretGroupHeroCard secretGroup={secretGroup}>
            <Grid item xs={12} sm='auto'>
              <Button
                onClick={() => {
                  navigate('/secrets')
                }}
                variant='text'
                color='primary'
                startIcon={<ArrowBack />}
              >
                BACK
              </Button>
            </Grid>

            {permissions.SECRETS_ADD_EDIT && (
              <>
                <Grid item style={{ alignSelf: 'stretch' }}>
                  <Divider orientation='vertical' />
                </Grid>

                <Grid item xs={12} sm='auto'>
                  <Button
                    endIcon={<Edit />}
                    onClick={() => {
                      setIsSecretGroupAddEditDialogOpen(true)
                    }}
                    variant='text'
                  >
                    EDIT
                  </Button>
                </Grid>
              </>
            )}
          </SecretGroupHeroCard>
        </Grid>

        <Grid item xs={12}>
          <SecretsTable secretGroup={secretGroup} />
        </Grid>
      </Grid>
    </>
  )
}
