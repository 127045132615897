import { List, ListItem, SwipeableDrawer } from '@mui/material'
import { useAuth } from 'context'
import { AppMenuItem } from './AppMenuItem'
import MenuLogo from './MenuLogo'

interface AppMenuProps {
  isMenuOpen: boolean
  isMobile: boolean
  onCloseMenu: () => void
  onOpenMenu: () => void
}

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

export default function AppMenu({ isMenuOpen, isMobile, onOpenMenu, onCloseMenu }: AppMenuProps) {
  const { permissions } = useAuth()

  return (
    <SwipeableDrawer
      anchor={isMobile ? 'bottom' : 'left'}
      color='primary'
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      disableSwipeToOpen={false}
      ModalProps={{ keepMounted: true }}
      onClose={onCloseMenu}
      onOpen={onOpenMenu}
      open={isMenuOpen}
      PaperProps={
        isMobile
          ? {
              maxHeight: '80vh',
              overflowY: 'auto'
            }
          : {
              width: '250px',
              overflowX: 'hidden'
            }
      }
      swipeAreaWidth={128 /*192*/}
      sx={theme => ({ display: 'flex', width: '162px', maxWidth: '162px', zIndex: theme.zIndex.drawer })}
      variant={isMobile ? 'temporary' : 'permanent'}
    >
      <List
        component='div'
        disablePadding
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: '#4b2d08',
          backgroundImage: `url(${process.env.PUBLIC_URL + '/sidebar.png'})`,
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'no-repeat',
          minHeight: '100%'
        }}
      >
        <ListItem sx={theme => ({ marginBottom: theme.spacing(2) })}>
          <MenuLogo src={process.env.PUBLIC_URL + '/logo.png'} />
        </ListItem>

        {permissions.SALESPERSON && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/campaigns'>
            Campaigns
          </AppMenuItem>
        )}

        {permissions.SALESPERSON && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/opportunities'>
            Opportunities
          </AppMenuItem>
        )}

        {(permissions.WORK_ORDER_DETAILS_ADD_EDIT || permissions.WORK_ORDER_DETAILS_READ_ONLY) && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/work-orders'>
            Work Orders
          </AppMenuItem>
        )}

        {permissions.TASKS && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/tasks'>
            Tasks
          </AppMenuItem>
        )}

        {(permissions.TASKS || permissions.TIME_ENTRIES_ADD_EDIT) && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/time-entries'>
            Time Entries
          </AppMenuItem>
        )}

        {(permissions.TASKS || permissions.TIME_PUNCHES_ADD_EDIT) && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/time-punches'>
            Time Punches
          </AppMenuItem>
        )}

        {permissions.COMPANIES_AND_PEOPLE_ADD_EDIT && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/people'>
            People
          </AppMenuItem>
        )}

        {permissions.COMPANIES_AND_PEOPLE_ADD_EDIT && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/companies'>
            Companies
          </AppMenuItem>
        )}

        {/* <AppMenuItem onCloseMenu={onCloseMenu} to='/assets'>
          Assets
        </AppMenuItem> */}

        <AppMenuItem onCloseMenu={onCloseMenu} to='/reports'>
          Reports
        </AppMenuItem>

        {(permissions.SECRETS_ADD_EDIT || permissions.SECRETS_READ_ONLY) && (
          <AppMenuItem onCloseMenu={onCloseMenu} to='/secrets'>
            Secrets
          </AppMenuItem>
        )}

        <AppMenuItem onCloseMenu={onCloseMenu} to='https://spudsoftware.sharepoint.com'>
          Everything Spud
        </AppMenuItem>
      </List>
    </SwipeableDrawer>
  )
}
