// @ts-nocheck
import { debounce } from 'lodash'
import { useCallback } from 'react'

export { checkBday } from './checkBday'
export { checkSpudversary } from './checkSpudversary'
export { calculateHoursStillNeeded } from './calculateHoursStillNeeded'
export { decimalToHhMm } from './decimalToHhMm'
export { formatCurrency } from './formatCurrency'
export { formatDate } from './formatDate'
export { formatDateTime } from './formatDateTime'
export { formatDateValue } from './formatDateValue'
export { formatTime } from './formatTime'
export { getPayPeriod } from './getPayPeriod'
export { getReleaseNumber } from './getReleaseNumber'
export { getStatusCount } from './getStatusCount'
export { getTimePunched } from './getTimePunched'
export { hhMmToDecimal } from './hhMmToDecimal'
export { idIsNotNullUndefinedOrNew } from './idIsNotNullUndefinedOrNew'
export { legacy_hhMmToDecimal } from './legacy_hhMmToDecimal'

/**
 * Returns a query string of all object key-value pairs where value is explicitly
 * **not** `null` *and* **not** `undefined` *and* **not** `''`.
 *
 * i.e. `?{key}={value}&{key2}={value2}`
 */
export const URIQuery = ({ ...parameters }): string => {
  let query = ''

  // Do not pass `null` or `undefined` values in the query parameters
  const keys = Object.keys(parameters).filter(
    key => parameters[key] !== null && parameters[key] !== undefined && parameters[key] !== '' && parameters[key] !== []
  )

  if (keys.length > 0) {
    // keys[0] is only ever used on this line to begin the query string (which must
    // start with '?') and is removed with keys.shift().
    query = '?' + keys[0] + '=' + parameters[keys.shift()!]

    keys.forEach(key => (query = query + '&' + key + '=' + parameters[key]))
  }

  return query
}

export const useDebounce = (value: any, delay: number) => useCallback(debounce(value, delay), [])
