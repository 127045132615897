import { WorkOrderReleaseDto } from 'dtos'

export function getReleaseNumber(workOrderRelease: WorkOrderReleaseDto): string {
  let releaseNumber = ''

  if (workOrderRelease.isReleaseNumberNumeric) {
    releaseNumber = 'R'
  }

  releaseNumber = releaseNumber + (workOrderRelease.releaseNumber || '')

  return releaseNumber
}
