import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { ExplanationAccordion } from 'components/_template/accordion'
import { CampaignDto, CompanyDto, OpportunityDto, PersonDto, opportunityValidationSchema } from 'dtos'
import { Formik, getIn } from 'formik'
import { useEffect, useState } from 'react'
import * as controllers from 'controllers'

interface Props {
  initialValues: OpportunityDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: OpportunityDto) => void
  open: boolean
}

export default function OpportunityAddEditDialog({ initialValues, isLoading, onClose, onSave, open }: Props) {
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([])
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [people, setPeople] = useState<PersonDto[]>([])
  const [salespeople, setSalespeople] = useState<PersonDto[]>([])
  const [targetCompanyId, setTargetCompanyId] = useState<string>('')
  const [useExistingCompany, setUseExistingCompany] = useState<boolean>(true)
  const [useExistingPerson, setUseExistingPerson] = useState<boolean>(true)

  useEffect(() => {
    controllers.getCampaigns({ page: 0, pageSize: 2000, statuses: ['isActive'] }).then(response => {
      setCampaigns(response.value)
    })

    controllers.getCompanies({ page: 0, pageSize: 2000, statuses: ['isActive'] }).then(response => {
      setCompanies(response.value)
    })

    controllers.getPeople({ page: 0, pageSize: 2000, statuses: ['isActive'], permission: 'SALESPERSON' }).then(response => {
      setSalespeople(response.value)
      console.log(response.value)
    })
  }, [])

  useEffect(() => {
    setTargetCompanyId(initialValues?.targetCompany?.id ?? '')
  }, [initialValues])

  useEffect(() => {
    setUseExistingCompany(true)
    setUseExistingPerson(true)
  }, [open])

  useEffect(() => {
    if (targetCompanyId) {
      controllers.getPeople({ page: 0, pageSize: 2000, companyId: targetCompanyId, statuses: ['isActive'] }).then(response => {
        setPeople(response.value)
      })
    }
  }, [targetCompanyId])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={opportunityValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Opportunity</DialogTitle>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('salesperson', value)
                      }}
                      options={salespeople.map(person => ({
                        id: person.id,
                        firstName: person.firstName,
                        lastName: person.lastName
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.salesperson && errors.salesperson)}
                          helperText={touched.salesperson && errors.salesperson}
                          label='Salesperson'
                          name='salesperson'
                        />
                      )}
                      value={
                        values.salesperson
                          ? {
                              id: values.salesperson.id,
                              firstName: values.salesperson.firstName,
                              lastName: values.salesperson.lastName
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name!}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('campaign', value)
                      }}
                      options={campaigns.map(campaign => ({
                        id: campaign.id,
                        name: campaign.name
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.campaign && errors.campaign)}
                          helperText={touched.campaign && errors.campaign}
                          label='Campaign'
                          name='campaign'
                        />
                      )}
                      value={
                        values.campaign
                          ? {
                              id: values.campaign.id,
                              name: values.campaign.name
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.opportunityName && errors.opportunityName)}
                      helperText={touched.opportunityName && errors.opportunityName}
                      label='Opportunity Name'
                      name='opportunityName'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.opportunityName || ''}
                    />
                  </Grid>

                  <Grid item xs={12} container spacing={1} alignItems='center'>
                    {useExistingCompany ? (
                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={isLoading || isSubmitting}
                          disableListWrap
                          getOptionLabel={option => option.name}
                          onBlur={handleBlur}
                          onChange={(_e, value) => {
                            setFieldValue('targetCompany', value)
                            setFieldValue('targetPerson', null)

                            setTargetCompanyId(value?.id ?? '')
                          }}
                          options={companies}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.targetCompany && typeof errors.targetCompany === 'string' && errors.targetCompany
                              )}
                              helperText={
                                // errors.targetCompany can be an object with errors for the child properties instead
                                // in that case, it should not attempt to display the errors on this field
                                touched.targetCompany && typeof errors.targetCompany === 'string' && errors.targetCompany
                              }
                              label='Target Company'
                              name='targetCompany'
                            />
                          )}
                          value={values.targetCompany ?? null}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading || isSubmitting}
                          error={Boolean(getIn(touched, 'targetCompany.name') && getIn(errors, 'targetCompany.name'))}
                          helperText={getIn(touched, 'targetCompany.name') && getIn(errors, 'targetCompany.name')}
                          fullWidth
                          label='Target Company Name'
                          name='targetCompany.name'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.targetCompany?.name || ''}
                        />
                      </Grid>
                    )}
                    <Link
                      onClick={() => {
                        if (!(isLoading || isSubmitting)) {
                          if (useExistingCompany) {
                            setFieldValue('targetCompany', new CompanyDto())
                            setFieldValue('targetPerson', null)
                          } else {
                            setFieldValue('targetCompany', null)
                          }
                          setUseExistingCompany(!useExistingCompany)
                        }
                      }}
                      sx={{ cursor: 'pointer', ml: 2 }}
                    >
                      {useExistingCompany ? 'Add New Company' : 'Use Existing Company'}
                    </Link>
                  </Grid>

                  <Grid item xs={12} container spacing={1} alignItems='center'>
                    {useExistingPerson ? (
                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={isLoading || isSubmitting}
                          disableListWrap
                          getOptionLabel={option => option.firstName + ' ' + option.lastName}
                          onBlur={handleBlur}
                          onChange={(_e, value) => {
                            setFieldValue('targetPerson', value)
                          }}
                          options={people.map(person => ({
                            id: person.id,
                            firstName: person.firstName,
                            lastName: person.lastName
                          }))}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.targetPerson && typeof errors.targetPerson === 'string' && errors.targetPerson
                              )}
                              helperText={
                                // errors.targetPerson can be an object with errors for the child properties instead
                                // in that case, it should not attempt to display the errors on this field
                                touched.targetPerson && typeof errors.targetPerson === 'string' && errors.targetPerson
                              }
                              label='Target Person'
                              name='targetPerson'
                            />
                          )}
                          value={
                            values.targetPerson
                              ? {
                                  id: values.targetPerson.id,
                                  firstName: values.targetPerson.firstName,
                                  lastName: values.targetPerson.lastName
                                }
                              : null
                          }
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isLoading || isSubmitting}
                            error={Boolean(getIn(touched, 'targetPerson.firstName') && getIn(errors, 'targetPerson.firstName'))}
                            helperText={getIn(touched, 'targetPerson.firstName') && getIn(errors, 'targetPerson.firstName')}
                            fullWidth
                            label='First Name'
                            name='targetPerson.firstName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            value={values.targetPerson?.firstName || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isLoading || isSubmitting}
                            error={Boolean(getIn(touched, 'targetPerson.lastName') && getIn(errors, 'targetPerson.lastName'))}
                            helperText={getIn(touched, 'targetPerson.lastName') && getIn(errors, 'targetPerson.lastName')}
                            fullWidth
                            label='Last Name'
                            name='targetPerson.lastName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            value={values.targetPerson?.lastName || ''}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            disabled={isLoading || isSubmitting}
                            error={Boolean(getIn(touched, 'targetPerson.email') && getIn(errors, 'targetPerson.email'))}
                            helperText={getIn(touched, 'targetPerson.email') && getIn(errors, 'targetPerson.email')}
                            fullWidth
                            label='Email'
                            name='targetPerson.email'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            value={values.targetPerson?.email || ''}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            disabled={isLoading || isSubmitting}
                            error={Boolean(
                              getIn(touched, 'targetPerson.mobilePhone') && getIn(errors, 'targetPerson.mobilePhone')
                            )}
                            helperText={getIn(touched, 'targetPerson.mobilePhone') && getIn(errors, 'targetPerson.mobilePhone')}
                            fullWidth
                            label='Mobile Phone'
                            name='targetPerson.mobilePhone'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            value={values.targetPerson?.mobilePhone || ''}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            disabled={isLoading || isSubmitting}
                            error={Boolean(getIn(touched, 'targetPerson.workPhone') && getIn(errors, 'targetPerson.workPhone'))}
                            helperText={getIn(touched, 'targetPerson.workPhone') && getIn(errors, 'targetPerson.workPhone')}
                            fullWidth
                            label='Work Phone'
                            name='targetPerson.workPhone'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            value={values.targetPerson?.workPhone || ''}
                          />
                        </Grid>
                      </>
                    )}
                    <Link
                      onClick={() => {
                        if (!(isLoading || isSubmitting)) {
                          if (useExistingPerson) {
                            setFieldValue('targetPerson', new PersonDto())
                          } else {
                            setFieldValue('targetPerson', null)
                          }
                          setUseExistingPerson(!useExistingPerson)
                        }
                      }}
                      sx={{ cursor: 'pointer', ml: 2 }}
                    >
                      {useExistingPerson ? 'Add New Person' : 'Use Existing Person'}
                    </Link>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.opportunityDescription && errors.opportunityDescription)}
                      helperText={touched.opportunityDescription && errors.opportunityDescription}
                      label='Description'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='opportunityDescription'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.opportunityDescription || ''}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12}>
                    <ExplanationAccordion>All fields are required.</ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
