import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import LabelField from 'components/LabelField'
import { Chip } from 'components/chips'
import { OpportunityDto } from 'dtos'
import React from 'react'
import { formatDate } from 'utils'

interface OpportunityHeroCardProps {
  opportunity: OpportunityDto
}

export default function OpportunityHeroCard({ children, opportunity }: React.PropsWithChildren<OpportunityHeroCardProps>) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
              <Grid item>
                <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                  {opportunity.opportunityName}
                </Typography>
              </Grid>

              {opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier ===
                'OPPORTUNITY_STATUSES_PROSPECT' && (
                <Grid item>
                  <Chip variant='blue'>Prospect</Chip>
                </Grid>
              )}

              {opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier ===
                'OPPORTUNITY_STATUSES_OPPORTUNITY' && (
                <Grid item>
                  <Chip variant='green'>Opportunity</Chip>
                </Grid>
              )}

              {opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier ===
                'OPPORTUNITY_STATUSES_SEND_PROPOSAL' && (
                <Grid item>
                  <Chip variant='yellow'>Send Proposal</Chip>
                </Grid>
              )}

              {opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier ===
                'OPPORTUNITY_STATUSES_GET_APPROVAL' && (
                <Grid item>
                  <Chip variant='purple'>Get Approval</Chip>
                </Grid>
              )}

              {opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier ===
                'OPPORTUNITY_STATUSES_GET_PAYMENT' && (
                <Grid item>
                  <Chip variant='blue'>Get Payment</Chip>
                </Grid>
              )}

              {opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier === 'OPPORTUNITY_STATUSES_SOLD' && (
                <Grid item>
                  <Chip variant='green'>Sold</Chip>
                </Grid>
              )}

              {opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier === 'OPPORTUNITY_STATUSES_LOST' && (
                <Grid item>
                  <Chip variant='red'>Lost</Chip>
                </Grid>
              )}

              {opportunity.isPastDue &&
                opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier !== 'OPPORTUNITY_STATUSES_LOST' &&
                opportunity.lastOpportunityActivity?.opportunityStatus?.specialIdentifier !== 'OPPORTUNITY_STATUSES_SOLD' && (
                  <Grid item>
                    <Chip variant='red'>Past Due</Chip>
                  </Grid>
                )}

              {/* {person.isActive && (
                <Grid item>
                  <Chip variant='green'>Active</Chip>
                </Grid>
              )} */}
            </Grid>

            <Grid item xs={12} sm='auto' container alignItems='center' spacing={1}>
              {children}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField
              label='Target Company'
              value={opportunity.targetCompany?.name}
              to={opportunity.targetCompany ? `/companies/${opportunity.targetCompany.id}` : ''}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField
              label='Target Person'
              value={
                opportunity.targetPerson ? `${opportunity.targetPerson.firstName} ${opportunity.targetPerson.lastName}` : ''
              }
              to={opportunity.targetPerson ? `/people/${opportunity.targetPerson.id}` : ''}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField label='Description' value={opportunity.opportunityDescription} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField
              label='Last Activity Date'
              value={opportunity.lastOpportunityActivity ? formatDate(opportunity.lastOpportunityActivity.createdDateTime) : ''}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField label='Last Activity Type' value={opportunity.lastOpportunityActivity?.opportunityActivityType?.name} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField label='Last Activity Note' value={opportunity.lastOpportunityActivity?.activityNote} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField
              label='Next Activity Date'
              value={
                opportunity.lastOpportunityActivity ? formatDate(opportunity.lastOpportunityActivity.nextActivityDate) : ''
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelField
              label='Next Activity Type'
              value={opportunity.lastOpportunityActivity?.opportunityNextActivityType?.name}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
