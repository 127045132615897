import { AppRegistration } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material'
import { Chip } from 'components/chips'
import { WorkOrderDto } from 'dtos'
import moment from 'moment'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { formatCurrency } from 'utils'

interface WorkOrderListCardProps {
  workOrder: WorkOrderDto
}

export default function WorkOrderListCard({ workOrder }: WorkOrderListCardProps) {
  const navigate = useNavigate()

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={true} container spacing={0} alignItems='center'>
              <Grid item>
                <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                  {workOrder.project?.name} {workOrder.workOrderNumber ? '#' + workOrder.workOrderNumber : ''}
                </Typography>
              </Grid>

              {workOrder.isOpen && (
                <Grid item>
                  <Chip variant='green'>Open</Chip>
                </Grid>
              )}

              {workOrder.isOpen && workOrder.isOutOfHours && (
                <Grid item>
                  <Chip variant='red'>Out of Hours</Chip>
                </Grid>
              )}

              {workOrder.isOpen === false && (
                <Grid item>
                  <Chip variant='red'>Closed</Chip>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm='auto'>
              <Button
                endIcon={<AppRegistration />}
                onClick={() => {
                  navigate('/work-orders/' + workOrder.id)
                }}
                variant='text'
              >
                DETAILS
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2' fontWeight='bold'>
              Company
            </Typography>
            <Typography color='primary' variant='body1' fontWeight='bold'>
              <Link component={ReactRouterLink} to={'/companies/' + workOrder.company?.id}>
                {workOrder.company?.name}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Primary Contact</Typography>
            <Typography color='primary' variant='body1'>
              <Link component={ReactRouterLink} to={'/people/' + workOrder.companyPerson?.id}>
                {workOrder.companyPerson?.firstName} {workOrder.companyPerson?.lastName}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Project Manager</Typography>
            <Typography color='primary' variant='body1'>
              <Link component={ReactRouterLink} to={'/people/' + workOrder.projectManager?.id}>
                {workOrder.projectManager?.firstName} {workOrder.projectManager?.lastName}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Billing Type</Typography>
            <Typography color='primary' variant='body1'>
              {workOrder.workOrderBillingType?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Bill Rate</Typography>
            <Typography color='primary' variant='body1'>
              {workOrder.billRate ? formatCurrency(workOrder.billRate) : ''}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Work Order Type</Typography>
            <Typography color='primary' variant='body1'>
              {workOrder.workOrderType?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Purchase Order Number</Typography>
            <Typography color='primary' variant='body1'>
              {workOrder.purchaseOrderNumber}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Opportunity</Typography>
            <Typography color='primary' variant='body1'>
              <Link component={ReactRouterLink} to={'/opportunities/' + workOrder.opportunity?.id}>
                {workOrder.opportunity?.opportunityName}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Salesperson</Typography>
            <Typography color='primary' variant='body1'>
              <Link component={ReactRouterLink} to={'/people/' + workOrder.salesperson?.id}>
                {workOrder.salesperson?.firstName} {workOrder.salesperson?.lastName}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Development</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={workOrder.devUrl} target='_blank'>
                {workOrder.devUrl}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Staging</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={workOrder.qaUrl} target='_blank'>
                {workOrder.qaUrl}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Production</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={workOrder.prodUrl} target='_blank'>
                {workOrder.prodUrl}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Days Since Last Activity</Typography>
            <Typography color='primary' variant='body1'>
              {workOrder.daysSinceLastActivity}
            </Typography>
          </Grid>

          {workOrder.workOrderBillingType?.specialIdentifier === 'TIME_AND_MATERIAL_PRE_PAID' && (
            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Prepaid Hours Remaining</Typography>
              <Typography color='primary' variant='body1'>
                {workOrder.prepaidHoursRemaining}
              </Typography>
            </Grid>
          )}

          {workOrder.workOrderBillingType?.specialIdentifier === 'FIXED_PRICE' && (
            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Fixed Price Hours Remaining</Typography>
              <Typography color='primary' variant='body1'>
                {workOrder.fixedPriceHoursRemaining}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Open Invoices</Typography>
            <Typography color='primary' variant='body1'>
              {workOrder.openInvoicesCount}
            </Typography>
          </Grid>

          {!workOrder.isOpen && (
            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Closed Date</Typography>
              <Typography color='primary' variant='body1'>
                {moment.utc(workOrder.closedDate).format('MM/DD/yyyy')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
