export function checkBday(birthMonth: number, birthDate: number) {
  // Get the current date
  const currentDate = new Date()
  // Get the current month and day
  const currentMonth = currentDate.getMonth() // Note: getMonth() returns 0 for January, 11 for December
  const currentDay = currentDate.getDate()
  // Set up the target date for the given month and day in the current year
  const targetDate = new Date(currentDate.getFullYear(), birthMonth - 1, birthDate)
  // Calculate the difference in time (milliseconds)
  const timeDifference = targetDate.getTime() - currentDate.getTime()
  // Calculate the difference in days
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24))

  if (currentMonth === birthMonth - 1 && currentDay === birthDate) {
    return 'B-Day Today!'
  } else if (daysDifference === 1 || daysDifference === -365) {
    return `B-day in 1 day`
  } else if (daysDifference === 2 || daysDifference === -364) {
    return `B-day in 2 days`
  } else if (daysDifference === 3 || daysDifference === -363) {
    return `B-day in 3 days`
  } else {
    return ''
  }
}
